import React from "react";
import { LayoutIndex } from "@/routers/constant";
import { RouteObject } from "@/routers/interface";
import lazyLoad from "@/routers/utils/lazyLoad";

const taskRouter: Array<RouteObject> = [
	{
		path: "/task",
		element: <LayoutIndex />,
		meta: {
			title: "任务管理"
		},
		children: [{
			path: "/task",
			element: lazyLoad(React.lazy(() => import("@/views/task/index"))),
			meta: {
				requiresAuth: false,
				title: "任务管理",
			}
		}]
	}
];

export default taskRouter;
