// import { useRef } from "react";
import { Avatar, Modal, Menu, Dropdown, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
// import { HOME_URL } from "@/config/config";
import { connect } from "react-redux";
import { setToken } from "@/reduxSelf/modules/global/action";
// import PasswordModal from "./PasswordModal";
// import InfoModal from "./InfoModal";
import avatar from "@/assets/images/avatar.png";
import { logoutApi } from "@/api/modules/login";
import { Login } from "@/api/interface";
import { store } from "@/reduxSelf";

const AvatarIcon = (props: any) => {
	const { setToken } = props;
	const navigate = useNavigate();

	// interface ModalProps {
	// 	showModal: (params: { name: number }) => void;
	// }
	// const passRef = useRef<ModalProps>(null);
	// const infoRef = useRef<ModalProps>(null);
    let userInfo = store.getState().global.userInfo as Login.ResLogin

	// 退出登录
	const logoutClick = async () => {
		try {
			await logoutApi()
			setToken("");
			message.success("退出登录成功！");
		} finally {
			setToken("");
			navigate("/login");
		}
	};
	// 退出登录
	const logout = () => {
		Modal.confirm({
			title: "温馨提示 🧡",
			icon: <ExclamationCircleOutlined />,
			content: "是否确认退出登录？",
			okText: "确认",
			cancelText: "取消",
			onOk: () => {
				logoutClick()
			}
		});
	};
	// Dropdown Menu
	const items =[
				// {
				// 	key: "1",
				// 	label: <span className="dropdown-item">首页</span>,
				// 	onClick: () => navigate(HOME_URL)
				// },
				// {
				// 	key: "2",
				// 	label: <span className="dropdown-item">个人信息</span>,
				// 	onClick: () => infoRef.current!.showModal({ name: 11 })
				// },
				// {
				// 	key: "3",
				// 	label: <span className="dropdown-item">修改密码</span>,
				// 	onClick: () => passRef.current!.showModal({ name: 11 })
				// },
				// {
				// 	type: "divider"
				// },
				{
					key: "4",
					label: <span className="dropdown-item">退出登录</span>,
				}
			];
		
	return (
		<>
			<Dropdown menu={{items,onClick:(e)=>{
					if(e.key === '1'){
						//  navigate(HOME_URL)
					}
					if(e.key === '2'){
						// infoRef.current!.showModal({ name: 11 })
				  }
					if(e.key === '3'){
						// passRef.current!.showModal({ name: 11 })
					}
					if(e.key === '4'){
						logout();
				  }
			  }}} placement="bottom" trigger={["hover"]}>
				<Avatar size="large" src={avatar} />
			</Dropdown>
			{/* <InfoModal innerRef={infoRef}></InfoModal> */}
			{/* <PasswordModal innerRef={passRef}></PasswordModal> */}
		</>
	);
};

const mapDispatchToProps = { setToken };
export default connect(null, mapDispatchToProps)(AvatarIcon);
