import React from "react";
import { LayoutIndex } from "@/routers/constant";
import { RouteObject } from "@/routers/interface";
import lazyLoad from "../utils/lazyLoad";

const notifyurlRouter: Array<RouteObject> = [
	{
		path: "/gpu",
		element: <LayoutIndex />,
		meta: {
			title: "GPU管理"
		},
		children: [{
			path: "/gpu/gpurecords",
			element: lazyLoad(React.lazy(() => import("@/views/gpu/gpurecords/index"))),
			meta: {
				requiresAuth: false,
				title: "GPU使用记录",
			}
		}, {
			path: "/gpu/gpuresource",
			element: lazyLoad(React.lazy(() => import("@/views/gpu/gpuresource/index"))),
			meta: {
				requiresAuth: false,
				title: "GPU资源管理",
			}
		}]
	}
];

export default notifyurlRouter;
