import React from "react";
import { RouteObject } from "@/routers/interface";
import { LayoutIndex } from "@/routers/constant";

import lazyLoad from "@/routers/utils/lazyLoad";

const imageRouter: Array<RouteObject> = [
	{
		path: "/model",
		element:<LayoutIndex />,
		meta: {
			title: "主播管理"
		},
		children: [
			{
				path: "/model/teacher",
				element: lazyLoad(React.lazy(() => import("@/views/model/teacher/index"))),
				meta: {
					requiresAuth: false,
					title: "主播管理",
				},
			}
		]
	}
];

export default imageRouter;
