import * as types from "@/reduxSelf/mutation-types";
import { ThemeConfigProp } from "@/reduxSelf/interface/index";
import { Login} from "@/api/interface/index";
// * setToken
export const setToken = (token: string) => ({
	type: types.SET_TOKEN,
	token
});

// * secretKey
export const setSecretKey = (secretKey: string) => ({
	type: types.SECRET_KEY,
	secretKey
});

// * setUserInfo
export const setUserInfo = (userInfo: Login.ResLogin) => ({
	type: types.SET_USERINFO,
	userInfo
});

// * setAssemblySize
export const setAssemblySize = (assemblySize: string) => ({
	type: types.SET_ASSEMBLY_SIZE,
	assemblySize
});

// * setLanguage
export const setLanguage = (language: string) => ({
	type: types.SET_LANGUAGE,
	language
});

// * setThemeConfig
export const setThemeConfig = (themeConfig: ThemeConfigProp) => ({
	type: types.SET_THEME_CONFIG,
	themeConfig
});
