import { connect } from "react-redux";
import "./index.less";

const LayoutFooter = (props: any) => {
	return (
		<>
			{(
				<div className="footer1">
					<a href="https://fasionai.com" target="_blank" rel="noreferrer">
						2024 © Fasion.AI
					</a>
				</div>
			)}
		</>
	);
};

const mapStateToProps = (state: any) => state.global;
export default connect(mapStateToProps)(LayoutFooter);
