import React from "react";
import { LayoutIndex } from "@/routers/constant";
import { RouteObject } from "@/routers/interface";
import lazyLoad from "../utils/lazyLoad";

const notifyurlRouter: Array<RouteObject> = [
	{
		path: "/notify",
		element: <LayoutIndex />,
		meta: {
			title: "通知管理"
		},
		children: [{
			path: "/notify/url",
			element: lazyLoad(React.lazy(() => import("@/views/notifyurl/index"))),
			meta: {
				requiresAuth: false,
				title: "通知地址",
			}
		}, {
			path: "/notify/records",
			element: lazyLoad(React.lazy(() => import("@/views/notifyrecords/index"))),
			meta: {
				requiresAuth: false,
				title: "通知记录",
			}
		}]
	}
];

export default notifyurlRouter;
