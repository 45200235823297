import React from "react";
import { LayoutIndex } from "@/routers/constant";
import { RouteObject } from "@/routers/interface";
import lazyLoad from "../utils/lazyLoad";

const taskRouter: Array<RouteObject> = [
	{
		path: "/apiTesting",
		element: <LayoutIndex />,
		meta: {
			title: "API测试"
		},
		children: [{
			path: "/apiTesting",
			element: lazyLoad(React.lazy(() => import("@/views/apiTest/index"))),
			meta: {
				requiresAuth: false,
				title: "API测试",
			}
		}]
	}
];

export default taskRouter;
