import "@/styles/reset.less";
import "@/assets/iconfont/iconfont.less";
import "@/assets/fonts/font.less";
import "@/styles/common.less";
import "@/language/index";
import "virtual:svg-icons-register";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "@/reduxSelf";
import App from "@/App";
// import react from "@vitejs/plugin-react";
import React from "react";
// import ReactDOM from "react-dom";
import ReactDOM from "react-dom/client";
// import 'element-theme-default';
import Provider from "react-redux/es/components/Provider";

ReactDOM.createRoot(document.getElementById("root")!).render(
	// * react严格模式
	<React.StrictMode>
		<Provider store={store}>
			<PersistGate persistor={persistor}>
				<App />
			</PersistGate>
		</Provider>
	</React.StrictMode>
);
