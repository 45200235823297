import logo from "@/assets/images/favicon.svg";
import { Space } from "antd";
import { connect } from "react-redux";

const Logo = (props: any) => {
	const { isCollapse } = props;
	return (
		<div className="logo-box">
			{!isCollapse ? <Space>
				<img src={logo} alt="logo" className="logo-img" />
				<h3 className="logo-text">模型管理</h3>
				</Space> : null}
		</div>
	);
};

const mapStateToProps = (state: any) => state.menu;
export default connect(mapStateToProps)(Logo);
